
  import { Component, Watch } from 'vue-property-decorator'
  import Cell from './cell'
  import ProgressCell from '@/components/dataTables/cell/ProgressCell.vue'
  import dayjs from 'dayjs'
  import { fixDate } from '@/utils/general'

@Component({
  components: { ProgressCell },
})
  export default class ConsignmentExpirationCell extends Cell {
  expired = 0
  expiredDate = null
  // Methods

  // Getters

  get progress () {
    const { expiredDate, expired } = this
    const today = dayjs()

    if (!expired) {
      return {
        metadata: {
          progress: 0,
        },
      }
    }
    const exp = dayjs(fixDate(expiredDate))
    // Cálculo del tiempo total desde la creación hasta el vencimiento
    const totalDuration = expired // número de días hasta que expire

    // Tiempo transcurrido desde la creación del item hasta hoy
    const elapsedTime = exp.diff(today, 'day')
    // Si ya se ha pasado la fecha de expiración, el progreso es 100
    const progress = elapsedTime <= 0
      ? 100 // Si el tiempo ha expirado o llega a 0, el progreso es 100
      : ((totalDuration - elapsedTime) / totalDuration) * 100

    return {
      metadata: {
        progress: Math.min(progress, 100), // asegura que el progreso no pase del 100%
      },
    }
  }

  get expirationStatus () {
    const { expiredDate, expired } = this

    const today = dayjs()

    if (!expired) {
      return ''
    }
    const exp = dayjs(fixDate(expiredDate))

    // Días desde la creación del item hasta hoy
    const elapsedTime = exp.diff(today, 'day')

    if (elapsedTime < 0) {
      // Si ya ha expirado, calcula cuántos días han pasado desde la expiración
      const daysSinceExpired = Math.abs(elapsedTime)
      return `Venció hace ${daysSinceExpired} días`
    } else {
      // Si aún no ha expirado, calcula cuántos días faltan
      return `Quedan ${elapsedTime} días`
    }
  }

  // Watchers
  @Watch('item', { immediate: true, deep: true })
  async onItemChange (val) {
    const document = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: { _and: [{ id_process_record: { _eq: val.id } }, { type: { name: { _eq: 'consignment_contract' } } }] },
      force: true,
    }))[0]

    if (document?.metadata) {
      this.expired = document?.metadata?.consignment_period?.days

      this.expiredDate = dayjs(document.date).add(this.expired, 'day').format('DD/MM/YYYY')
    }
  }
  }
